/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "@angular/flex-layout/mq";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "./styles/colors";
@import "./styles/fonts";
@import "./styles/palettes";
@import "./styles/global";

@include mat.core();

$agv-primary: mat.define-palette($sabesp-primary-palette);
$agv-accent: mat.define-palette($sabesp-secondary-palette, A200, A100, A400);
$agv-dark: mat.define-palette($sabesp-secondary-palette);

$agv-warn: mat.define-palette(mat.$red-palette);

.default-theme {
  $agv-theme: mat.define-light-theme(
    (
      color: (
        primary: $agv-primary,
        accent: $agv-accent,
        warn: $agv-warn,
      ),
    )
  );
  @include mat.all-component-themes($agv-theme);
  .dark-mode {
    display: none;
  }
}

.theme-dark {
  $agv-theme: mat.define-dark-theme(
    (
      color: (
        primary: $agv-dark,
        accent: $agv-accent,
        warn: $agv-warn,
      ),
    )
  );
  @include mat.all-component-colors($agv-theme);
  .default-mode {
    display: none;
  }

  .agrupa-components .mat-expansion-panel {
    border: 1px solid var(--color-black) !important;
    border-top: none;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
  outline: 0;
}

html,
body {
  height: 100%;
  background-color: var(--color-white-grey);

  .mat-expansion-panel-header,
  .mat-expansion-panel-content,
  .mat-table,
  .mat-tab-label,
  .mat-tab-link,
  .mat-form-field,
  .mat-radio-button,
  .mat-card,
  .mat-tab-group,
  .mat-checkbox{
    font-family: "Satoshi", sans-serif !important;
  }
}

button,
a {
  cursor: pointer;
}

.mat-tab-label-active {
  .mat-tab-label-content {
    color: var(--color-primary);
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--color-white-input-background) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-black) !important;
}

.mat-tab-label {
  opacity: 1 !important;
  color: var(--color-black) !important;
  font-size: 1.25rem;
  height: 90px !important;
  padding: 0 !important;
}

.selecionar-fornecimento {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    background: transparent;
    border: 1px solid var(--color-gray-weak);
    border-radius: 5px;
    padding: 5px 15px 0px 15px;
  }

  .mat-select-min-line {
    margin-top: 20px;
    display: block;
  }
}

.default-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.login {
  .mat-form-field-flex {
    border: 1px solid var(--color-gray-weak);
    background-color: var(--color-white-input-background) !important;
    border-radius: 5px !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    height: 0px;
  }

  .mat-option-text {
    color: black;
  }

  .login_input_cpf.failed,
  .login_input_senha.failed {
    .mat-form-field-flex {
      border: 1px solid var(--color-failed);
    }
  }
}

.filtro-faturas {
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: white;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background: transparent;
    border: 1px solid var(--color-gray-weak);
    border-radius: 5px;
    padding: 15px;
  }
}

.custom-tabs-selection {
  .mat-ink-bar {
    background-color: var(--color-primary) !important;
    width: 80px !important;
    margin-left: 16% !important;
    height: 9px !important;

    @media (max-width: 600px) {
      margin-left: 12.5% !important;
    }
  }

  .mat-tab-header .mat-tab-label-container {
    padding-left: 60px;
    padding-right: 60px;
  }

  .mat-tab-label .mat-tab-label-content {
    white-space: initial;
    margin-bottom: 10px;
  }

  > .mat-tab-header .mat-tab-label-container .mat-tab-label {
    width: 100vw !important;
    max-width: 270px !important;

    @media (max-width: 600px) {
      width: 160px !important;
    }
  }

  @media (max-width: 600px) {
    .mat-tab-body-content {
      min-height: 400px;
      height: auto;
      overflow-y: hidden;
    }
  }
}

.mat-tab-body-wrapper {
  width: calc(100vw - 17px);

  @media (max-width: 600px) {
    width: calc(100vw);
  }
}

.header__tab_group {
  min-width: 347px !important;
  .mat-tab-links {
    .mat-tab-link {
      height: 40px !important;
      width: 100%;
      max-width: 102px;
      cursor: pointer !important;
      box-sizing: border-box !important;
      font-size: 0.819rem !important;
      opacity: 1 !important;
      min-width: 85px !important;
      text-align: left !important;
      display: inline-flex !important;
      justify-content: center !important;
      align-items: center !important;
      white-space: nowrap !important;
      vertical-align: top !important;
      text-decoration: none !important;
      position: relative !important;
      overflow: hidden !important;
      font-weight: 400 !important;

      &:nth-child(2) {
        min-width: 137px !important;
      }
    }

    .mat-tab-label-active {
      font-weight: 700 !important;
      color: var(--color-primary) !important;
    }
  }

  .mat-ink-bar {
    height: 4px !important;
  }
}

.fatura-selecionada-container {
  .formas-pagamento {
    @media (max-width: 600px) {
      width: 95%;
    }

    .mat-tab-header {
      border: none !important;
      padding-top: 55px;
      background: linear-gradient(
        0deg,
        var(--color-primary-white-table) 34%,
        var(--color-blue-700) 25%
      ); /* Cor padrão para o tema claro */
    }

    @media (prefers-color-scheme: dark) {
      @media (max-width: 768px) {
        width: 95vw;
      }
    }

    .mat-tab-list {
      @media (max-width: 768px) {
        margin-right: 18px;
      }
    }
  }

  .box__details {
    .mat-expansion-panel-header {
      width: 100%;
      padding: 10px;
    }

    .mat-expansion-panel-header-title {
      min-width: 740px;
      @media (max-width: 600px) {
        min-width: 220px;
      }
    }

    .mat-expansion-panel-header-description {
      color: var(--color-always-white);
      font-size: 2rem;
    }
    .mat-expansion-panel.mat-expanded .mat-expansion-panel-header-description {
      color: var(--color-always-white);
      font-size: 2rem;
      font-size: 0;
    }

    .mat-expansion-panel.mat-expanded
      .mat-expansion-panel-header-description::after {
      content: "-";
      font-size: 2rem;
      color: var(--color-always-white);
    }
  }
}

.formas-pagamento {
  .mat-tab-header {
    border: none !important;
    padding-top: 55px;
    width: 100vw;
  }

  .mat-tab-body-wrapper {
    max-width: 1080px;
    text-align: center;
    margin: auto;
  }

  .mat-tab-group {
    .mat-primary {
      width: 100%;
    }
  }

  .mat-tab-header.mat-tab-header::before {
    content: "Formas de pagamento";
    text-align: center;
    margin: auto;
    display: block;
    width: 100%;
    height: 30px;
    color: var(--color-always-white);
    position: absolute;
    top: 10px;
    font-weight: 500;
    font-size: 1.25rem;
  }
}
//Ver porque uma classe css de cadastrar-cliente esta no style global? (isso pode estragar o comportamento de outros componentes)
.cadastrar-cliente {
  .cadastrar-cliente_dados {
    .cadastrar-cliente_dados-cpf .mat-form-field-flex {
      opacity: 0;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-invalid {
      border: 1px solid #f44336 !important;
    }
  }
}

:focus,
mat-checkbox.cdk-focused {
  border: 4px dashed #d89023 !important;
}

.text-decoration {
  text-decoration: underline;
}

.mensagem-erro {
  a {
    text-decoration: underline;
    font-weight: 600;
    color: var(--color-link) !important;
  }
}

.broken-line {
  @media (max-width: 1115px) {
    display: none !important;
  }
}

.theme-dark {
  .icon {
    filter: invert(150%);
  }
}

.textCapitalize {
  text-transform: capitalize;
}

.img-ativo {
  display: none;
}

.mat-tab-label-active .img-inativo {
  display: none;
}

.mat-tab-label-active .img-ativo {
  display: block;
  margin-bottom: 5px;
}

.mat-tab-label.mat-tab-disabled {
  cursor: not-allowed;
  .mat-tab-label-content {
    opacity: 0.6;
  }
}

// Classes para o modal de erro
.linkModal {
  font-weight: 700;
  text-decoration: underline;
}

.mat-tab-group.mat-primary
  .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled) {
  //Remover o transparente dos métodos de pagamengo quando está com o focus e é usada a seta do teclado para mudar a opção
  background: var(--color-always-white) !important;
}

button {
  &:disabled {
    color: var(--color-always-grey) ;
  }
}

.cdk-overlay-container {
  overflow-y: auto;
}

button[disabled] {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer !important;
}

.link{
  text-decoration: underline;
  color: var(--color-action-blue);
  font-weight: 600;
}
.solicitacoes-archor{
  color: var(--color-action-blue);
  font-weight: 600;
  
}

.custom-select-fornecimento{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-normal-grey);
  justify-content: space-between;
  font-size: 0.825rem;
  width: 100%;
  .endereco{
    max-width: 170px;
    font-weight: 400;
    max-width: 40%;
  }
  .fornecimento{
    font-weight: 800;
  }
}