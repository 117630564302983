@import "@angular/flex-layout/mq";

.mat-drawer-container {
  background-color: var(--color-white-grey) !important;
}

.mat-calendar {
  background: var(--color-white) !important;
}

//Modal personalizado de erro de limite de parcelamento
.custom-dialog-error__limite-parcelamento {
  width: 475px !important;
  height: 374px !important;
  .dialog-error {
  }
}

//Dialog inicio aviso nova AGV
.dialog-aviso-nova-agv {
  @include layout-bp(xs) {
    height: 496px;
    width: 313px;
  }
  .mat-dialog-container {
    padding: 0 !important;
    // hack para que o focus não modifique o componente
    &:focus {
      padding: 0 !important;
      margin: 0 !important;
      overflow: hidden !important;
    }
  }
}
//Dialog inicio aviso nova AGV

.dialog-aviso-cnpj {
  @include layout-bp(xs) {
    margin: 0 1rem;
  }
  .mat-dialog-container {
    padding: 0;

    @include layout-bp(xs) {
      padding: 1rem;
    }

    // hack para que o focus não modifique o componente
    &:focus {
      padding: 0 !important;
      margin: 0 !important;
      overflow: hidden !important;
    }
  }
}
